import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { request } from './apiHelper';

export interface Person {
  id: number
  name: string
  stats: {
    all: number,
    reserved: number,
    reservedByYou: number,
  }
  // families: Family
}

interface Stats {
  all: number,
  reserved: number,
  reservedByYou: number,
  person: Person
}

// interface Family {
//   id: number
//   name: string
//   people: Person[]
// }

export interface FamilyContextData {
  family: Person[]
  getFamily: () => void;
}

export function useFamilyContextValue(): FamilyContextData {
  const [family, setFamily] = useState<Person[]>([]);

  const getFamily = useCallback(async () => {
    request({ method: 'GET', url: '/gifts/stats'})
      .then((response: Stats[]) => {
        setFamily(response.map((stats: Stats) => {
          return {
            name: stats.person.name,
            id: stats.person.id,
            stats: {
              all: stats.all,
              reserved: stats.reserved,
              reservedByYou: stats.reservedByYou,
            }
          }
        }))
      })
  //     // .finally(() => {
  //     //   setIsLoading(false);
  //     // })
  }, [setFamily]);

  return useMemo(() => ({
    family,
    getFamily,
  }), [
    family,
    getFamily,
  ]);
}
export const FamilyContext = createContext<FamilyContextData | undefined>(undefined);

export function useFamilyContext() {
  const familyContext = useContext(FamilyContext);
  if (!familyContext) {
    throw new Error('useFamilyContext must be used within the FamilyContext.Provider');
  }
  return familyContext;
}


