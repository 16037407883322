import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '../utils/apiHelper';
import { Person, useFamilyContext } from '../utils/familyContext';
import Grid from '@material-ui/core/Grid';
import GiftsGroup from './GiftsGroup';
import { OwnGift } from './OwnGifts';
import PageHeader from "../components/PageHeader";

export interface OtherGift {
  id: number
  name: string
  description: string
  isPrivate: boolean
  getters: Person[]
  givers?: Person[]
  links: string[]
  isReserved: boolean
  isCreatedByGetter: boolean
  isCreatedByUser: boolean
}

export const isOtherGift = (gift: OtherGift | OwnGift): gift is OtherGift =>
  (gift as OtherGift).isReserved !== undefined;

interface GroupedOtherGifts {
  gifts: OtherGift[],
  createdByGetterGifts: OtherGift[],
  privateGifts: OtherGift[],
}

interface ParamTypes {
  personId?: string
}

const OtherGifts: React.FC<{}> = () => {
  const [gifts, setGifts] = useState<GroupedOtherGifts>({
    gifts: [],
    createdByGetterGifts: [],
    privateGifts: [],
  });
  let { personId } = useParams<ParamTypes>();
  const { family } = useFamilyContext();
  const per: Person | undefined = (family.find((p) => !!personId && p.id === +personId));

  const getOtherGifts = useCallback(() => {
      request({ method: 'GET', url: '/gifts/other', params: { getterId: personId } })
        .then((response: OtherGift[]) => {
          const groupedGifts: GroupedOtherGifts = {
            gifts: [],
            createdByGetterGifts: [],
            privateGifts: [],
          };
          response.forEach((gift) => {
            if (gift.isPrivate) {
              groupedGifts.privateGifts.push(gift);
            }
            else if (gift.isCreatedByGetter) {
              groupedGifts.createdByGetterGifts.push(gift);
            }
            else {
              groupedGifts.gifts.push(gift);
            }
          });
          setGifts(groupedGifts);
        });
    }, [personId],
  );

  useEffect(() => {
    setGifts({
      gifts: [],
      createdByGetterGifts: [],
      privateGifts: [],
    });
    getOtherGifts();
  }, [personId, getOtherGifts]);

  return (
    <>
      <PageHeader>{per?.name}</PageHeader>
      <Grid container>
        <GiftsGroup title={'Prezenty zwyczajne:'} gifts={gifts.gifts} refreshGifts={getOtherGifts}/>
        <GiftsGroup title={'Stworzeone przez zainteresowanego:'} gifts={gifts.createdByGetterGifts} refreshGifts={getOtherGifts}/>
        <GiftsGroup title={'Prezenty prywatne:'} gifts={gifts.privateGifts} refreshGifts={getOtherGifts}/>
      </Grid>
    </>
  );
};

export default OtherGifts;