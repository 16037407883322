import Drawer from '@material-ui/core/Drawer';
import React from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudIcon from '@material-ui/icons/Cloud';
import Family from './Family';
import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../utils/constants';
import ListItemNavLink from '../components/ListItemNavLink';
import { Icon } from '@material-ui/core';
import { useAuthenticatedContext } from '../utils/authenticatedContext';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    height: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  icon: {
    textAlign: 'center',
  }
}));

const MenuDrawer: React.FC<{menuOpen: boolean, setMenuOpen: (open: boolean) => void}> = ({menuOpen, setMenuOpen}) => {
  const classes = useStyles();
  const { isChildren } = useAuthenticatedContext();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
      }}
      open={menuOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => setMenuOpen(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItemNavLink to={'/gifts/own/add'}>
          <ListItemIcon className={classes.icon}>
            <Icon className="fa fa-lightbulb"/>
          </ListItemIcon>
          <ListItemText primary="Dodaj swoje marzenie" />
        </ListItemNavLink>

        {!isChildren ? <ListItemNavLink to={'/gifts/other/add'}>
          <ListItemIcon className={classes.icon}>
            <Icon className="fa fa-hand-holding-heart" />
          </ListItemIcon>
          <ListItemText primary="Zaskocz kogoś" />
        </ListItemNavLink> : null}

        <Divider />

        {!isChildren ? <ListItemNavLink to={'/people/add'}>
          <ListItemIcon className={classes.icon}>
            <PersonAddIcon/>
          </ListItemIcon>
          <ListItemText primary="Dodaj sobie Mateusza" />
        </ListItemNavLink> : null}

        <Divider/>

        <ListItemNavLink to={'/gifts/own'}>
          <ListItemIcon className={classes.icon}>
            <CloudIcon />
          </ListItemIcon>
          <ListItemText primary="Twoje marzenia" />
        </ListItemNavLink>

        <Divider />

        {!isChildren ? <ListItemNavLink to={'/elves-list'}>
          <ListItemIcon className={classes.icon}>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Lista prac elfów" />
        </ListItemNavLink> : null}
      </List>
      <Divider />
      {!isChildren ? <Family/> : null}
    </Drawer>
  )
}

export default MenuDrawer