import React, { useEffect, useState } from 'react';
import { request } from '../utils/apiHelper';
import { Person } from '../utils/familyContext';
import Grid from '@material-ui/core/Grid';
import OwnGiftCard from './OwnGiftCard';
import CardGridItem from './GiftCard/CardGridItem';

export interface OwnGift {
  id: number
  name: string
  description: string
  isPrivate: boolean
  getters: Person[]
  links: string[]
}

const OwnGifts: React.FC<{}> = () => {
  const [gifts, setGifts] = useState<OwnGift[]>([]);

  useEffect(() => {
    setGifts([])
    getGifts();
  }, []);

  const getGifts = () => {
    request({ method: 'GET', url: '/gifts/own'})
      .then((response: OwnGift[]) => {
        setGifts(response)
      });
  }

  return (
    <Grid container spacing={2}>
      {gifts.map((gift) => (
        <CardGridItem key={gift.id}>
          <OwnGiftCard gift={gift} refreshGifts={getGifts}/>
        </CardGridItem>
      ))}
    </Grid>
  );
};

export default OwnGifts;