import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { OwnGift } from '../OwnGifts';
import { isOtherGift, OtherGift } from '../OtherGifts';
import { request } from '../../utils/apiHelper';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Icon } from '@material-ui/core'
import MultipleReserve from './MultipleReserve';

interface Props {
  gift: OtherGift | OwnGift
  refreshGifts: () => void
}

const ITEM_HEIGHT = 48;

const GiftOperations: React.FC<Props> = ({ gift, refreshGifts }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory()

  const handleDeleteClickOpen = () => {
    setDialogOpen(true);
    handleClose();
  };

  const handleDeleteClose = () => {
    setDialogOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    const path = isOtherGift(gift) ? 'other' : 'own'
    history.push(`/gifts/${path}/edit/${gift.id}`)
  };

  const handleDelete = () => {
    request({ method: 'DELETE', url: `/gifts/${gift.id}` })
      .then(() => {
        enqueueSnackbar('Prezent usunięty', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Coś nie tak!', {
          variant: 'error',
        });
      })
      .finally(() => {
        refreshGifts();
      });
    handleDeleteClose();
  };

  const isCreatedByUser: boolean = !!((!isOtherGift(gift)) || (gift as OtherGift)?.isCreatedByUser);

  return (
    <>
      <IconButton aria-label="settings"
                  aria-controls="settings"
                  aria-haspopup="true"
                  onClick={handleClick}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id="settings"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: isOtherGift(gift) ? '30ch' : '20ch',
          },
        }}
      >
        {isOtherGift(gift) ?
          <MultipleReserve gift={gift} onOpen={handleClose} refreshGifts={refreshGifts} disabled={gift.isReserved && (!gift.givers?.length)}>
            <MenuItem disabled={gift.isReserved && (!gift.givers?.length)}>
              <ListItemIcon>
                <Icon className="fa fa-gift"/>
              </ListItemIcon>
              <Typography variant="inherit">Zarezerwuj wspólnie</Typography>
            </MenuItem>
          </MultipleReserve>: null}

        <MenuItem onClick={handleEdit} disabled={!isCreatedByUser}>
          <ListItemIcon>
            <EditIcon fontSize="small"/>
          </ListItemIcon>
          <Typography variant="inherit">Edytuj</Typography>
        </MenuItem>

        <MenuItem onClick={handleDeleteClickOpen} disabled={!isCreatedByUser}>
          <ListItemIcon>
            <DeleteIcon fontSize="small"/>
          </ListItemIcon>
          <Typography variant="inherit">Usuń</Typography>
        </MenuItem>
        <Dialog
          open={dialogOpen}
          onClose={handleDeleteClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">{'Czy na pewno usunąć ten prezent?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Prezent: {gift.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              Nie
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </Menu>
    </>

  );
};

export default GiftOperations;