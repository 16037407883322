import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OtherGiftCard from './OtherGiftCard';
import { OtherGift } from './OtherGifts';
import CardGridItem from './GiftCard/CardGridItem';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2),
    },
  },
}));

const GiftsGroup: React.FC<{ title?: string, gifts: OtherGift[], refreshGifts: () => void }> = ({ title, gifts, refreshGifts }) => {
 const classes = useStyles()
  return (
    <>
      {gifts.length ? <>
        <Typography variant="h5" color="textPrimary" className={classes.title}>{title}</Typography>
        <Grid container spacing={2}>
          {gifts.map((gift) => (
            <CardGridItem key={gift.id}>
              <OtherGiftCard gift={gift} refreshGifts={refreshGifts}/>
            </CardGridItem>
          ))}
        </Grid></> : null}
    </>
  );
};

export default GiftsGroup