import { request } from './apiHelper';

interface SignUpResponse {
  accessToken: string
}

const TOKEN_NAME = 'prezencikToken';

class AuthService {
  login(username: string, password:string) {
    const data = {username, password};
    return request({ method: 'POST', url: '/auth/signin', data}).then((response: SignUpResponse) =>
    {
      this.setJWTToken(response.accessToken);
      return response
    });
  }

  logout() {
    this.removeJWTToken()
  }

  setJWTToken = (token: string) => localStorage.setItem(TOKEN_NAME, token);

  getJWTToken = () => localStorage.getItem(TOKEN_NAME) || null;

  removeJWTToken = () => localStorage.removeItem(TOKEN_NAME);


  // register(username, email, password) {
  //   return axios.post(API_URL + "signup", {
  //     username,
  //     email,
  //     password
  //   });
  // }
  //
  // getCurrentUser() {
  //   return JSON.parse(localStorage.getItem('user'));;
  // }
}

export default new AuthService();