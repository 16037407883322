import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import GiftCardContent from './GiftCard/GiftCardContent'
import ReservedGiftCardHeader from './GiftCard/ReservedGiftCardHeader'
import { ReservedGift } from './ElvesList'

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  actions: {
    marginTop: "auto",
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface Props {
  gift: ReservedGift
  refreshGifts: () => void
}

const ReservedGiftCard: React.FC<Props> = ({ gift, refreshGifts }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <ReservedGiftCardHeader gift={gift} refreshGifts={refreshGifts}/>
      <GiftCardContent gift={gift} showAllGetters={true}/>
    </Card>
  );
};

export default ReservedGiftCard