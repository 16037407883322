import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import { Person, useFamilyContext } from '../utils/familyContext'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import Button from '@material-ui/core/Button'
import LockIcon from '@material-ui/icons/Lock'
import { Chip } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { request } from '../utils/apiHelper'
import { useAuthenticatedContext } from '../utils/authenticatedContext'
import { useHistory, useParams } from 'react-router-dom'
import Autocomplete from '../components/Autocomplete'
import PageHeader from "../components/PageHeader";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

interface Values {
  name: string
  description: string
  links: string[]
  isPrivate: boolean
  getters: Person[]
}

interface ParamTypes {
  giftId?: string
}

interface Props {
  isOwn: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  linkChips: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}))

const initialGift = {
  name: '',
  description: '',
  links: [],
  isPrivate: false,
  getters: [],
}

const AddGift: React.FC<Props> = ({ isOwn }) => {
  const classes = useStyles()
  const history = useHistory()
  const { family } = useFamilyContext()
  const { person } = useAuthenticatedContext()

  const [gift, setGift] = useState<Values>({ ...initialGift })

  let { giftId } = useParams<ParamTypes>()

  const getGift = useCallback(() => {
    if (giftId) {
      const path = isOwn ? 'own' : 'other'
      request({ method: 'GET', url: `/gifts/${path}/${giftId}` })
        .then((response: Values) => {
          setGift(response)
        })
    }
    else {
      setGift({ ...initialGift, getters: (isOwn && person ? [person] : []) })
    }
  }, [giftId, isOwn, person])

  useEffect(() => {
    getGift()
  }, [getGift])

  const people: Person[] = [...family]
  if (person) {
    people.push(person)
  }

  return (
    <>
      <PageHeader>{isOwn ? 'Dodaj swoje marzenie' : 'Zaskocz kogoś'}</PageHeader>
      <Paper className={classes.root}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...gift, getters: people.filter((p) => gift.getters.find((g) => g.id === p.id)) }}
          //TODO: ts ignore
          // @ts-ignore
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>,
          ) => {
            const data = values
            // debugger
            const method = giftId ? 'PATCH' : 'POST'
            const url = giftId ? `/gifts/${giftId}` : '/gifts'
            request({ method, url, data })
              .then((response: any) => {
                const path = isOwn || values.getters.find(g => g.id === person?.id)
                  ? 'own' : `other/${values.getters[0].id}`
                history.replace(`/gifts/${path}`)
              }).catch(() => {
              setSubmitting(false)
            })
            //  TODO: co ma sie dziac dalej + jakis alert
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Field
                component={TextField}
                name="name"
                label="Nazwa"
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <Field
                component={TextField}
                name="description"
                label="Opis"
                multiline
                rowsMax={4}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="isPrivate"
                Label={{ label: 'Prywatny' }}
                checkedIcon={<LockIcon color={'primary'}/>}
              />
              <Field
                component={Autocomplete}
                name='links'
                multiple
                freeSolo
                autoSelect
                options={[]}
                renderTags={(value: string[], getTagProps: any) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })}
                          className={classes.linkChips}/>
                  ))
                }
                textFieldProps={{ fullWidth: true, margin: 'normal', variant: 'outlined', label: 'Linki' }}
              />
              <Field
                component={Autocomplete}
                name='getters'
                multiple
                disableCloseOnSelect
                options={people}
                getOptionLabel={(option: Person) => {
                  return option.name
                }}
                renderOption={(option: Person, { selected }: { selected: boolean }) =>
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                }
                textFieldProps={{ fullWidth: true, margin: 'normal', variant: 'outlined', label: 'Otrzymywacze' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Wyślij do Świetego Mikołaja
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  )
}

export default AddGift