import React from 'react';
import { Avatar, Chip, Icon } from '@material-ui/core';
import { OwnGift } from '../OwnGifts';
import { OtherGift } from '../OtherGifts';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

interface Props {
  gift: OtherGift | OwnGift
  showAllGetters?: boolean
}

function addhttp(url: string) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url
}

const Link = (link: string, key: string) => {
  try {
    const url = new URL(addhttp(link));
    return <Chip
        key={key}
        variant="outlined"
        size="small"
        avatar={<Avatar><Icon className="fa fa-globe"/></Avatar>}
        label={url.origin}
        onClick={() => {window.open(url.toString(), '_blank');}}
    />
  } catch (e) {
    console.log(e + "link: " + link)
  }

  return null
}

const GiftCardContent: React.FC<Props> = ({ gift, showAllGetters }) => {
  return (
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        {gift.description}
      </Typography>
      {showAllGetters || gift.getters.length > 1 ? <Typography variant="body2" color="textSecondary" component="div">
        <Icon className="fa fa-users" style={{ fontSize: "1rem", width: "auto", marginRight: "0.125rem" }}/>{gift.getters.map((getter) => getter.name).join(' | ')}
      </Typography> : null}
      {!!gift.links && gift.links.map((link, id) => Link(link, id.toString()))}
    </CardContent>
  );
};

export default GiftCardContent;