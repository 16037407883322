import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './utils/routing';
import PageContainer from './pages/PageContainer';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AddGift from './pages/AddGift';
import OtherGifts from './pages/OtherGifts';
import OwnGifts from './pages/OwnGifts';
import { useAuthenticatedContext } from './utils/authenticatedContext';
import ElvesList from './pages/ElvesList'
import AddPerson from './pages/AddPerson';

const Routing: React.FC<{}> = () => {
  const { isChildren } = useAuthenticatedContext();
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <PageContainer><Dashboard/></PageContainer>
      </PrivateRoute>
      <Route path="/login">
        <Login/>
      </Route>
      {!isChildren ? <PrivateRoute exact path="/gifts/other/add">
        <PageContainer><AddGift isOwn={false}/></PageContainer>
      </PrivateRoute> : null}
      {!isChildren ? <PrivateRoute exact path="/gifts/other/edit/:giftId">
        <PageContainer><AddGift isOwn={false}/></PageContainer>
      </PrivateRoute> : null}
      {!isChildren ? <PrivateRoute path="/gifts/other/:personId">
        <PageContainer><OtherGifts/></PageContainer>
      </PrivateRoute> : null}
      {!isChildren ? <PrivateRoute path="/elves-list">
        <PageContainer><ElvesList/></PageContainer>
      </PrivateRoute> : null}
      {!isChildren ? <PrivateRoute path="/people/add">
          <PageContainer><AddPerson/></PageContainer>
      </PrivateRoute> : null}
      <PrivateRoute exact path="/gifts/own">
        <PageContainer><OwnGifts/></PageContainer>
      </PrivateRoute>
      <PrivateRoute exact path="/gifts/own/add">
        <PageContainer><AddGift isOwn={true}/></PageContainer>
      </PrivateRoute>
      <PrivateRoute exact path="/gifts/own/edit/:giftId">
        <PageContainer><AddGift isOwn={true}/></PageContainer>
      </PrivateRoute>
    </Switch>
  );
};

export default Routing;