import * as React from 'react'
import {Field, Form, Formik, FormikHelpers} from 'formik'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import {Person, useFamilyContext} from '../utils/familyContext'
import {TextField} from 'formik-material-ui'
import Button from '@material-ui/core/Button'
import {Chip} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {request} from '../utils/apiHelper'
import Autocomplete from '../components/Autocomplete'
import {useSnackbar} from "notistack";
import PageHeader from '../components/PageHeader'

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  linkChips: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}))

interface Values {
  name: string
  newPeople: string[]
  people: Person[]
}

const AddPerson: React.FC = () => {
  const classes = useStyles()
  const { family, getFamily } = useFamilyContext()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>,) => {
    const data = {
      ...values,
      newPeople: values.newPeople.map((el) => { return {name: el} })
    }
    request({ method: 'POST', url: '/people', data })
      .then(async (response: any) => {
        enqueueSnackbar('Mateusz dodany', {
          variant: 'success',
        })
        await getFamily()
        resetForm()
      }).catch(() => {
        setSubmitting(false)
    })
  }

  return (
    <>
    <PageHeader>Dodaj sobie Mateusza</PageHeader>
      <Paper className={classes.root}>
        <Formik
          initialValues={{ name: '', newPeople: [], people: [] }}
          //TODO: ts ignore
          // @ts-ignore
          onSubmit={onSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <Field
                component={TextField}
                name="name"
                label="Nazwa rodziny"
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <Field
                component={Autocomplete}
                name='newPeople'
                multiple
                freeSolo
                autoSelect
                options={[]}
                renderTags={(value: string[], getTagProps: any) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })}
                          className={classes.linkChips}/>
                  ))
                }
                textFieldProps={{ fullWidth: true, margin: 'normal', variant: 'outlined', label: 'Nowi Mateusze' }}
              />
              <Field
                component={Autocomplete}
                name='people'
                multiple
                disableCloseOnSelect
                options={family}
                getOptionLabel={(option: Person) => {
                  return option.name
                }}
                renderOption={(option: Person, { selected }: { selected: boolean }) =>
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                }
                textFieldProps={{ fullWidth: true, margin: 'normal', variant: 'outlined', label: 'Z kim chcesz współdzielić Mateusza? :)' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Dodaj!
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  )
}

export default AddPerson