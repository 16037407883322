import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Icon } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import CardHeader from '@material-ui/core/CardHeader';
import { OwnGift } from '../OwnGifts';
import { OtherGift } from '../OtherGifts';
import GiftOperations from './GiftOperations';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: red[500],
  },
}));

interface Props {
  gift: OtherGift | OwnGift
  refreshGifts: () => void
}

const GiftCardHeader: React.FC<Props> = ({ gift, refreshGifts }) => {
  const classes = useStyles();

  return (
    <CardHeader
      avatar={
        <Avatar aria-label="recipe" className={classes.avatar}>
          <Icon className="fa fa-candy-cane"/>
        </Avatar>
      }
      action={
        <GiftOperations gift={gift} refreshGifts={refreshGifts}/>
      }
      title={gift.name}
      // subheader="September 14, 2016"
    />
  );
};

export default GiftCardHeader;