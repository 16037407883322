import React from 'react'
import { useFamilyContext } from '../utils/familyContext'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles, Theme } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemNavLink from '../components/ListItemNavLink'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    initials: {
      fontWeight: 500,
      fontSize: '0.85rem'
    }
  }),
);

const Family: React.FC<{ }> = () => {
  const classes = useStyles();
  const { family } = useFamilyContext();

  return (
    <List className={classes.root} component="nav" >
      {family.map((person) =>
        <ListItemNavLink
          key={person.id}
          to={`/gifts/other/${person.id}`}
        >
          <ListItemAvatar>
            <Avatar className={classes.small}>
              {/*<PersonIcon />*/}
              <Typography variant="body2" color="primary" className={classes.initials}>
                {person.name.split(" ").map((n)=>n[0]).join("")}
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${person.name} ${person.stats.reserved}/${person.stats.all}`} />
        </ListItemNavLink>
      )}
    </List>
  )
}

export default Family