import React from 'react';
import { AuthenticatedContext, useAuthenticatedContextValue } from './utils/authenticatedContext';
import { FamilyContext, useFamilyContextValue } from './utils/familyContext';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { SnackbarProvider } from 'notistack';
import Routing from './Routing';

function App() {
  const authenticatedContextValue = useAuthenticatedContextValue();
  const familyContextValue = useFamilyContextValue();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: red[800]
      },
      secondary: {
        main: green[400]
      },
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthenticatedContext.Provider value={authenticatedContextValue}>
          <FamilyContext.Provider value={familyContextValue}>
            <Routing/>
          </FamilyContext.Provider>
        </AuthenticatedContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
