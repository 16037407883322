import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles, Theme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 345,
      maxWidth: "100%",
    },
  }),
);

const CardGridItem: React.FC<{}> = ({children}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      {children}
    </Grid>
  )
}

export default CardGridItem
