import React, {useCallback, useEffect, useState} from 'react'
import {request} from '../utils/apiHelper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {OtherGift} from './OtherGifts'
import CardGridItem from './GiftCard/CardGridItem'
import ReservedGiftCard from './ReservedGiftCard'

export interface ReservedGift extends OtherGift {
    status: Status
    info: string
}

export enum Status {
    reserved = 'reserved',
    found = 'found',
    bought = 'bought',
    inDelivery = 'inDelivery',
    collected = 'collected',
    packed = 'packed',
}

export const StatusPL = {
    reserved: 'Zarezerwowany',
    found: 'Znaleziony',
    bought: 'Kupiony',
    inDelivery: 'Wysłany',
    collected: 'Odebrany',
    packed: 'Zapakowany',
}

export const StatusOrder = [
    Status.reserved,
    Status.found,
    Status.bought,
    Status.inDelivery,
    Status.collected,
    Status.packed,
]

const useStyles = makeStyles((theme) => ({
    person: {
        marginBottom: theme.spacing(2),
        borderBottom: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.main,
    },
}))

const ElvesList: React.FC<{}> = () => {
    const classes = useStyles()
    const [gifts, setGifts] = useState<ReservedGift[]>([])

    const getReservedGifts = useCallback(() => {
            request({method: 'GET', url: '/gifts/reserved'})
                .then((response: ReservedGift[]) => {
                    setGifts(response.sort((a, b) => {
                        return StatusOrder.findIndex(order => order === a.status) - StatusOrder.findIndex(order => order === b.status)
                    }))
                })
        }, [],
    )

    useEffect(() => {
        getReservedGifts()
    }, [getReservedGifts])

    return (
        <>
            <Typography variant="h4" component={'div'} color="textPrimary" className={classes.person}>Lista dla
                Elfów:</Typography>
            <Grid container spacing={2}>
                {gifts.map((gift) => (
                    <CardGridItem key={gift.id}>
                        <ReservedGiftCard gift={gift} refreshGifts={getReservedGifts}/>
                    </CardGridItem>
                ))}
            </Grid>
        </>
    )
}

export default ElvesList
