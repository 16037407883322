import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container/Container';
import TextField from '@material-ui/core/TextField/TextField';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { useAuthenticatedContext } from '../utils/authenticatedContext';
import { Icon, SnackbarContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login: React.FC<{}> = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, isAuthenticated, errorMessage } = useAuthenticatedContext();
  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (isAuthenticated) {
      // TODO: ts-ignore?
      // @ts-ignore
      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    }
  }, [isAuthenticated, history, location.state])


  const handleLogin = useCallback((e: any) => {
    e.preventDefault()
    login(email, password)
  },[login, email, password])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon className="fa fa-sleigh" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Prezencik 2024
        </Typography>
        {errorMessage ? <SnackbarContent message={errorMessage} /> : null}
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Zaloguj elfie!
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Login
