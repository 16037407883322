import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    person: {
        marginBottom: theme.spacing(2),
        borderBottom: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(3),
        },
    },
}));
const PageHeader: React.FC = ({children}) => {
    const classes = useStyles();

    return (
        <Typography variant="h4" component={'div'} color="textPrimary"
                    className={classes.person}>{children}</Typography>
    )
}

export default PageHeader
