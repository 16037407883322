import React from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import { ReservedGift, StatusPL } from '../ElvesList'
import ReservedGiftOperations from './ReservedGiftOperations'


interface Props {
  gift: ReservedGift
  refreshGifts: () => void
}

const ReservedGiftCardHeader: React.FC<Props> = ({ gift, refreshGifts }) => {

  return (
    <CardHeader
      action={
        <ReservedGiftOperations gift={gift} refreshGifts={refreshGifts}/>
      }
      title={gift.name}
      subheader={[StatusPL[gift.status], gift.info].join(' | ')}
    />
  );
};

export default ReservedGiftCardHeader;