import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { OtherGift } from './OtherGifts';
import GiftCardHeader from './GiftCard/GiftCardHeader';
import GiftCardContent from './GiftCard/GiftCardContent';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import { Icon } from '@material-ui/core';
import { request } from '../utils/apiHelper';
import { useAuthenticatedContext } from '../utils/authenticatedContext';
import { Person } from '../utils/familyContext';
import { useSnackbar } from 'notistack';
import LockIcon from '@material-ui/icons/Lock'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  actions: {
    marginTop: "auto",
    display: 'flex',
    justifyContent: 'space-between',
  }
  // media: {
  //   height: 0,
  //   paddingTop: '56.25%', // 16:9
  // },
}));

interface Props {
  gift: OtherGift
  refreshGifts: () => void
}

const OtherGiftCard: React.FC<Props> = ({ gift, refreshGifts }) => {
  const classes = useStyles();
  const { person } = useAuthenticatedContext();
  const { enqueueSnackbar } = useSnackbar();


  const reserveGift = () => {
    const givers: Partial<Person>[] = gift.isReserved ? [] : [{id: person?.id}]
    sendGivers(givers)
  }

  const sendGivers = (givers: Partial<Person>[]) => {
    const data = {givers}
    request({ method: 'POST', url: `/gifts/${gift.id}/givers`, data})
      .then((response: Person[]) => {
        if (!response.length) {
          enqueueSnackbar('Odrezerwowany', {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Zarezerwowany', {
            variant: 'success',
          });
        }
      }).catch(() => {
      enqueueSnackbar('Coś nie tak!', {
        variant: 'error',
      });
      }).finally(()=> {
      refreshGifts()
    })
  }

  return (
    <Card className={classes.root}>
      <GiftCardHeader gift={gift} refreshGifts={refreshGifts}/>
      {/*<CardMedia*/}
      {/*  className={classes.media}*/}
      {/*  image="/static/images/cards/paella.jpg"*/}
      {/*  title="Paella dish"*/}
      {/*/>*/}
      <GiftCardContent gift={gift} />
      <CardActions disableSpacing className={classes.actions}>
        <Checkbox checkedIcon={<Icon className="fa fa-gift"/>} disabled={gift.isReserved && (!gift.givers?.length)} checked={gift.isReserved} onChange={reserveGift}/>
        {(gift.givers && gift.givers.length > 1) ? <Typography variant="body2" color="textSecondary" component="div">
          <Icon className="fa fa-people-carry" style={{ fontSize: "1rem", width: "auto", marginRight: "0.125rem" }}/>
          {gift.givers.filter((giver) => giver.id !== person?.id).map((giver) => giver.name).join(' | ')}
        </Typography> : null}
        {gift.isPrivate ? <LockIcon color={'primary'} /> : null}
        {/*  <IconButton aria-label="add to favorites">*/}
        {/*    <FavoriteIcon />*/}
        {/*  </IconButton>*/}
        {/*  <IconButton aria-label="share">*/}
        {/*    <ShareIcon />*/}
        {/*  </IconButton>*/}
      </CardActions>
    </Card>
  );
};

export default OtherGiftCard