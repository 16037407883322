import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AuthService from './authService';
import jwt_decode from "jwt-decode";
import { Person } from './familyContext';
import { CHILDREN } from './constants';

export interface AuthenticatedContextData {
  isAuthenticated: boolean
  isChildren: boolean
  token: string | null
  person: Person | undefined
  errorMessage: string | null
  login: (username: string, password: string) => void;
  logout: () => void;
}

interface JWTPayload {
  person: Person
  username: string
}

const getPersonFromJWT = (token: string | null) => {
  if (token === null) {
    return undefined
  }
  let decoded: JWTPayload = jwt_decode(token);
  return decoded.person
}

const isChildrenFromJWT = (token: string | null) => {
  if (token === null) {
    return false
  }
  let decoded: JWTPayload = jwt_decode(token);
  return CHILDREN.includes(decoded.username);
}

export function useAuthenticatedContextValue(): AuthenticatedContextData {
  const [isAuthenticated, setIsAuthenticated] = useState(!!(AuthService.getJWTToken()));
  const [isChildren, setIsChildren] = useState(isChildrenFromJWT(AuthService.getJWTToken()));
  const [token, setToken] = useState(AuthService.getJWTToken());
  const [person, setPerson] = useState(getPersonFromJWT(AuthService.getJWTToken()));
  const [errorMessage, setErrorMessage] = useState<string|null>(null);


  const login = useCallback((username:string, password: string) => {
    setErrorMessage(null)
    AuthService.login(username, password).then(response => {
      if (response.accessToken) {
        setToken(response.accessToken)
        setPerson(getPersonFromJWT(response.accessToken))
        setIsChildren(isChildrenFromJWT(response.accessToken))
        setIsAuthenticated(true);
      }
    }).catch(() => {
      setErrorMessage("UPS. Coś nie tak!")
    });
  }, [setIsAuthenticated]);

  //TODO: make mechanism to autologout after 401 api response code error
  useEffect(() => {
    if (!AuthService.getJWTToken()) {
      logout()
    }
  })

  const logout = useCallback(() => {
    AuthService.logout();
    setToken(null);
    setIsAuthenticated(false);
  }, [setIsAuthenticated]);

  return useMemo(() => ({
    isAuthenticated,
    isChildren,
    token,
    person,
    login,
    logout,
    errorMessage,
  }), [
    isAuthenticated,
    isChildren,
    token,
    person,
    login,
    logout,
    errorMessage,
  ]);
}
export const AuthenticatedContext = createContext<AuthenticatedContextData | undefined>(undefined);

export function useAuthenticatedContext() {
  const authenticatedContext = useContext(AuthenticatedContext);
  if (!authenticatedContext) {
    throw new Error('useAuthenticatedContext must be used within the AuthenticatedContext.Provider');
  }
  return authenticatedContext;
}


