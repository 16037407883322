import React from 'react';
import {Autocomplete as MaterialAutocomplete} from '@material-ui/lab';
import {TextField as MaterialTextField} from '@material-ui/core';
import {fieldToTextField} from 'formik-material-ui';


const Autocomplete: React.FC<any> = ({ textFieldProps, ...props }) => {

    const { form: { setTouched, setFieldValue } } = props;
    const { error, helperText, ...field } = fieldToTextField(props);
    const name = field.name ?? '';

    return (
        <MaterialAutocomplete
            {...props}
            {...field}
            onChange={ (_, value) => setFieldValue(name, value) }
            onBlur={ () => setTouched({ [name]: true }) }
            renderInput={ props => (
                <MaterialTextField {...props} {...textFieldProps} helperText={helperText} error={error} />
            )}
        />
    );
}

export default Autocomplete;