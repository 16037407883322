export const API_URL = process.env.REACT_APP_PROD_API_URL || "http://localhost:4000";
export const DRAWER_WIDTH = 280;

export const CHILDREN = [
  'paula',
  'pati',
  'natalia',
  'adam',
  'dominik',
  'kacper',
]