import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import GiftCardHeader from './GiftCard/GiftCardHeader';
import { OwnGift } from './OwnGifts';
import GiftCardContent from './GiftCard/GiftCardContent';

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  // media: {
  //   height: 0,
  //   paddingTop: '56.25%', // 16:9
  // },
}));

interface Props {
  gift: OwnGift
  refreshGifts: () => void}

const OwnGiftCard: React.FC<Props> = ({ gift , refreshGifts}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <GiftCardHeader gift={gift} refreshGifts={refreshGifts}/>
      {/*<CardMedia*/}
      {/*  className={classes.media}*/}
      {/*  image="/static/images/cards/paella.jpg"*/}
      {/*  title="Paella dish"*/}
      {/*/>*/}
      <GiftCardContent gift={gift} />
    </Card>
  );
};

export default OwnGiftCard;
