import React from 'react';
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
//   fixedHeight: {
//     height: 240,
//   },
// }));

const Dashboard: React.FC<{}> = () => {
  // const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (

    <div>
      {/*  <Grid container spacing={3}>*/}
      {/*    /!* Chart *!/*/}
      {/*    <Grid item xs={12} md={8} lg={9}>*/}
      {/*      <Paper className={fixedHeightPaper}>*/}
      {/*        /!*<Chart />*!/*/}
      {/*      </Paper>*/}
      {/*    </Grid>*/}
      {/*    /!* Recent Deposits *!/*/}
      {/*    <Grid item xs={12} md={4} lg={3}>*/}
      {/*      <Paper className={fixedHeightPaper}>*/}
      {/*        /!*<Deposits />*!/*/}
      {/*      </Paper>*/}
      {/*    </Grid>*/}
      {/*    /!* Recent Orders *!/*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Paper className={classes.paper}>*/}
      {/*        /!*<Orders />*!/*/}
      {/*      </Paper>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
    </div>
  )
}

export default Dashboard