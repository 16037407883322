import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { OtherGift } from '../OtherGifts'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import Autocomplete from '../../components/Autocomplete'
import { Person, useFamilyContext } from '../../utils/familyContext'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useAuthenticatedContext } from '../../utils/authenticatedContext'
import { request } from '../../utils/apiHelper'
import { useSnackbar } from 'notistack'

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

interface Props {
  gift: OtherGift
  disabled: boolean
  onOpen: () => void
  refreshGifts: () => void
}

interface Values {
  givers: Person[]
}

const MultipleReserve: React.FC<Props> = ({ children, gift, onOpen, refreshGifts, disabled }) => {
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { family } = useFamilyContext()
  const { person } = useAuthenticatedContext()

  const people: Person[] = family.filter((p) => !gift.getters.some((g) => g.id === p.id))

  if (person) {
    people.push(person)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    onOpen()
    setOpen(true)
  }

  return (
    <>
      <div onClick={() => {!disabled && handleClickOpen()}}>{children}</div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
              maxWidth={'md'}>
        <DialogTitle id="form-dialog-title">Zarezerwuj wspólnie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Z kim chcesz zarezerwowac prezent?
          </DialogContentText>
          <Formik
            enableReinitialize={true}
            initialValues={{ givers: people.filter((p) => gift.givers?.find((g) => g.id === p.id)) || (person ? [person] : []) }}
            //TODO: ts ignore
            // @ts-ignore
            onSubmit={(
              values: Values,
              { setSubmitting }: FormikHelpers<Values>,
            ) => {
              if (values.givers.length > 0 && !values.givers.some((g) => g.id === person?.id)) {
                alert('Musisz być jednym z dawaczy!')
                setSubmitting(false)
                return
              }
              const data = { givers: values.givers }
              request({ method: 'POST', url: `/gifts/${gift.id}/givers`, data })
                .then((response: Person[]) => {
                  if (!response.length) {
                    enqueueSnackbar('Odrezerwowany', {
                      variant: 'warning',
                    })
                  }
                  else {
                    enqueueSnackbar('Zarezerwowany', {
                      variant: 'success',
                    })
                  }
                  handleClose()
                }).catch(() => {
                enqueueSnackbar('Coś nie tak!', {
                  variant: 'error',
                })
              }).finally(() => {
                refreshGifts()
                setSubmitting(false)
              })
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Field
                  component={Autocomplete}
                  name='givers'
                  multiple
                  // disableCloseOnSelect
                  options={people}
                  getOptionLabel={(option: Person) => {
                    return option.name
                  }}
                  renderOption={(option: Person, { selected }: { selected: boolean }) =>
                    (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </>
                    )}
                  textFieldProps={{ fullWidth: true, margin: 'normal', variant: 'outlined', label: 'Dawacze' }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // className={classes.submit}
                >
                  Zarazerwuj
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
        {/*<DialogActions>*/}
        {/*  <Button onClick={handleClose} color="primary">*/}
        {/*    Cancel*/}
        {/*  </Button>*/}
        {/*  <Button onClick={handleClose} color="primary">*/}
        {/*    Subscribe*/}
        {/*  </Button>*/}
        {/*</DialogActions>*/}
      </Dialog>
    </>
  )
}

export default MultipleReserve
