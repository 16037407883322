import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import React from 'react';
import { Icon, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../utils/constants';
import { useAuthenticatedContext } from '../utils/authenticatedContext';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));

const TopBar: React.FC<{menuOpen: boolean, setMenuOpen: (open: boolean) => void}> = ({menuOpen, setMenuOpen}) => {
  const classes = useStyles();
  const { logout } = useAuthenticatedContext();

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, menuOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => {setMenuOpen(true)}}
          className={clsx(classes.menuButton, menuOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          <Icon className="fa fa-gift" /> Prezencik 2024
        </Typography>
        <IconButton color="inherit">
          {/*<Badge badgeContent={4} color="secondary">*/}
            <NotificationsIcon />
          {/*</Badge>*/}
        </IconButton>
        <IconButton color="inherit">
          <ExitToAppIcon
            onClick={logout}
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar