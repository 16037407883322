import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: theme.palette.secondary.main,
    }
  }),
);

const ListItemNavLink: React.FC<{to: string}> = ({children, to}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      component={NavLink} to={to}
      exact
      activeClassName={classes.active}
    >
      {children}
    </ListItem>
  )
}

export default ListItemNavLink
