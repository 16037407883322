import React, { useEffect } from 'react';
import TopBar from './TopBar';
import MenuDrawer from './MenuDrawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useFamilyContext } from '../utils/familyContext';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const PageContainer: React.FC<{}> = ({children}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const classes = useStyles();

  const { getFamily } = useFamilyContext();
  useEffect(() => {
    getFamily();
  }, [getFamily])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <MenuDrawer menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <>{children}</>
        </Container>
      </main>
    </div>
  )
}

export default PageContainer