import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from './constants';
import AuthService from './authService';

const client = axios.create({
  baseURL: API_URL,
})

const onSuccess = function (response: any) {
  //todo:
  console.debug('Request Successful!', response)
  return response.data
}

const onError = function (error: any) {
  console.error('Request Failed:', error.config)

  if (error.response) {
    if (error.response.status === 401) {
      AuthService.logout();
    }
    // Request was made but server responded with something
    // other than 2xx
    console.error('Status:', error.response.status)
    console.error('Data:', error.response.data)
    console.error('Headers:', error.response.headers)

  }
  else {
    // Something else happened while setting up the request
    // triggered the error
    console.error('Error Message:', error.message)
  }

  return Promise.reject(error.response || error.message)
}

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options: AxiosRequestConfig) {
  const token = AuthService.getJWTToken()
  options.headers = {
    'Authorization': `Bearer ${token}`,
  }
  if (process.env.NODE_ENV === 'development') {
    options.params = {
      ...options.params,
      XDEBUG_SESSION_START: 'PHPSTORM',
    }
  }
  return client(options).then(onSuccess).catch(onError)
}

