import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { request } from '../../utils/apiHelper'
import { useSnackbar } from 'notistack'
import { Icon } from '@material-ui/core'
import { ReservedGift, Status, StatusOrder, StatusPL } from '../ElvesList'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

interface Props {
  gift: ReservedGift
  refreshGifts: () => void
}

const ITEM_HEIGHT = 48

const ReservedGiftOperations: React.FC<Props> = ({ gift, refreshGifts }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [info, setInfo] = React.useState(gift.info)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { enqueueSnackbar } = useSnackbar()

  const handleInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfo(event.target.value);
  };
  const handleInfoClickOpen = () => {
    setDialogOpen(true);
    handleClose();
  };

  const handleInfoClose = () => {
    setInfo(gift.info)
    setDialogOpen(false);
  };

  const handleAddInfo = () => {
    sendNewStatus({status: gift.status, info: info})
    setDialogOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const findIndex = () => {
    return StatusOrder.findIndex((order) => gift.status === order)
  }

  const findNext = () => {
    return Status[StatusOrder[findIndex() + 1]] || false
  }

  const findPrev = () => {
    return Status[StatusOrder[findIndex() - 1]] || false
  }

  const handleNext = () => {
    sendNewStatus({status: findNext(), info: gift.info})
  }
  const handlePrev = () => {
    sendNewStatus({status: findPrev(), info: gift.info})
  }

  const sendNewStatus = (data: {status: Status, info: string}) => {
    request({ method: 'PATCH', url: `/gifts/reserved/${gift.id}`, data })
      .then(() => {
        enqueueSnackbar('Status zmieniony', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Coś nie tak!', {
          variant: 'error',
        })
      })
      .finally(() => {
        refreshGifts()
      })
    handleClose()
  }

  return (
    <>
      <IconButton aria-label="settings"
                  aria-controls="settings"
                  aria-haspopup="true"
                  onClick={handleClick}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id="settings"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        <MenuItem onClick={handleNext} disabled={!findNext()}>
          <ListItemIcon>
            <Icon fontSize="small" className="fa fa-arrow-alt-circle-right"/>
          </ListItemIcon>
          <Typography variant="inherit">{StatusPL[findNext()]}</Typography>
        </MenuItem>
        <MenuItem onClick={handlePrev} disabled={!findPrev()}>
          <ListItemIcon>
            <Icon fontSize="small" className="fa fa-arrow-alt-circle-left"/>
          </ListItemIcon>
          <Typography variant="inherit">{StatusPL[findPrev()]}</Typography>
        </MenuItem>
        <MenuItem onClick={handleInfoClickOpen}>
          <ListItemIcon>
            <Icon fontSize="small" className="fa fa-info-circle"/>
          </ListItemIcon>
          <Typography variant="inherit">Dodaj notatkę</Typography>
        </MenuItem>
        <Dialog
          open={dialogOpen}
          onClose={handleInfoClose}
          aria-labelledby="info-dialog-title"
          aria-describedby="info-dialog-description"
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle id="info-dialog-title">{'Dodaj notatkę do prezentu'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="info"
              label="Info"
              type="text"
              fullWidth
              value={info}
              onChange={handleInfoChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddInfo} color="primary" autoFocus>
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
      </Menu>
    </>

  )
}

export default ReservedGiftOperations